<template>
  <div class="st-input" :data-t="dataT">
    <div class="top-labels">
      <div v-if="label" class="label">
        <span>{{ label }}</span>
        <slot name="tip" />
      </div>
    </div>
    <div class="st-input-wrapper">
      <textarea
        ref="input"
        v-model="inputValue"
        data-t="input"
        class="input"
        rows="1"
        :type="type"
        :placeholder="placeholder"
        :autofocus="autofocus"
        :autocomplete="autocomplete"
        :class="inputClasses"
        :inputmode="inputmode"
        :maxlength="maxlength"
        :disabled="disabled || notEditable"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <div class="st-input-postfix-section">
        <slot name="postfix"></slot>
      </div>
    </div>
    <StTransitionExpand>
      <div v-if="hint || (error && errorMessage)">
        <span v-if="error && errorMessage" class="error-message" data-t="error">
          {{ errorMessage }}
        </span>
        <span v-else-if="hint" class="hint" data-t="hint">{{ hint }}</span>
      </div>
    </StTransitionExpand>
  </div>
</template>

<script setup lang="ts">
import type { InputHTMLAttributes, InputTypeHTMLAttribute } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue?: string
    label?: string
    placeholder?: string
    hint?: string
    error?: boolean
    errorMessage?: string
    disabled?: boolean
    type?: InputTypeHTMLAttribute
    autofocus?: boolean
    autocomplete?: string
    inputmode?: InputHTMLAttributes['inputmode']
    maxlength?: number
    dataT?: string
    notEditable?: boolean
  }>(),
  {
    modelValue: '',
    type: 'text',
    label: '',
    placeholder: '',
    hint: '',
    errorMessage: '',
    autocomplete: '',
    maxlength: 999,
    inputmode: 'text',
    dataT: 'st-textarea',
    size: 'm',
    notEditable: false,
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'blur'): void
  (e: 'focus'): void
}>()

const input = ref<HTMLTextAreaElement>()

const inputClasses = computed(() => ({
  error: props.error,
}))

onMounted(() => {
  if (props.autofocus) input.value?.focus()
})

const inputValue = computed({
  get: () => props.modelValue,
  set: (value: string) => emit('update:modelValue', value),
})

const isPlaceholderVisible = ref('visible')
function handleFocus() {
  isPlaceholderVisible.value = 'hidden'
  emit('focus')
}

function handleBlur() {
  isPlaceholderVisible.value = 'visible'
  emit('blur')
}

watch(inputValue, () => {
  if (!input.value) return

  input.value.rows = 1
  if (input.value.scrollHeight > input.value.offsetHeight) {
    input.value.rows = input.value.scrollHeight / 20 - 1
  }
})
</script>

<style scoped>
.st-input {
  position: relative;
  width: 100%;

  &.disabled {
    pointer-events: none;
    opacity: 0.48;
  }
}

.input {
  resize: none;

  display: block;

  width: 100%;
  padding: var(--spacing-125);

  font: var(--desktop-text-sm-medium);
  color: var(--text-primary);

  background-color: var(--background-secondary);
  border: none;
  border-radius: var(--border-radius-100);
  outline: none;

  &::placeholder {
    padding: 0;
    font: var(--desktop-text-sm-medium);
    color: var(--text-quaternary);
    visibility: v-bind(isPlaceholderVisible);
  }

  &:focus {
    box-shadow:
      0 0 0 1px var(--button-primary-default) inset,
      var(--focus-rings-button-primary);
  }

  &.error {
    box-shadow: 0 0 0 1px var(--system-error) inset;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: all 5000s ease-in-out 5000s;
    transition-property: color, background-color;
  }

  &:focus.error {
    box-shadow:
      0 0 0 1px var(--system-error) inset,
      var(--focus-rings-button-destructive);
  }
}

.hint {
  margin-top: var(--spacing-100);
  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);
}

.error-message {
  margin-top: var(--spacing-100);
  font: var(--desktop-text-xs-medium);
  color: var(--system-error);
}

.top-labels {
  display: flex;
  justify-content: space-between;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: var(--spacing-075);

  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.st-input-wrapper {
  display: flex;
  align-items: center;

  &:hover {
    .input {
      box-shadow: 0 0 0 1px var(--button-primary-default) inset;
    }
  }
}

.st-input-postfix-section {
  position: absolute;
  right: var(--spacing-150);
  display: flex;
  align-items: center;

  .st-input-postfix-icon {
    color: var(--text-quaternary);

    &:hover {
      color: var(--palette-light-1000);
    }
  }
}
</style>
