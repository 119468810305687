<template>
  <div class="add-new-address" :class="platform" data-t="add-new-address">
    <div v-if="current === 'first'" class="content">
      <div class="top-section">
        <h2>{{ t('payments.withdrawal.addNewWalletAddress') }}</h2>
        <StIcon
          v-if="platform === 'mobile'"
          name="cross-large"
          @click="goBack"
        />
      </div>
      <CurrencySelect
        v-model="currencyId"
        :platform="platform"
        operation-type="address"
      />
      <NetworkSelect
        v-model="networkId"
        :networks="networks"
        :platform="platform"
        operation-type="address"
      />
      <StTextarea
        v-if="platform === 'mobile'"
        v-model="walletAddress"
        size="m"
        :label="t('payments.withdrawal.walletAddress')"
        :placeholder="t('payments.addressBook.enterWalletAddress')"
        :error="!!errorMessage"
        :error-message="errorMessage"
        data-t="wallet-address-input-o6vs"
      >
        <template #postfix>
          <div class="postfix-wrapper">
            <div
              v-if="is2FADisabled && walletAddress"
              class="verified-address"
              data-t="verified-address-label-tv3c"
            >
              {{ t('payments.addressBook.confirmed') }}
            </div>
            <StButton
              v-if="!walletAddress"
              :label="t('payments.addressBook.paste')"
              type="text-tertiary"
              @click="pasteAddress"
            />
            <StIcon
              v-else
              name="cross-large"
              size="16"
              type="text-tertiary"
              class="clear-button"
              @click="clearAddress"
            />
          </div>
        </template>
      </StTextarea>
      <StInput
        v-else
        v-model="walletAddress"
        size="l"
        :label="t('payments.withdrawal.walletAddress')"
        :placeholder="t('payments.addressBook.enterWalletAddress')"
        :error="!!errorMessage"
        :error-message="errorMessage"
        data-t="wallet-address-input-5zyr"
      >
        <template #postfix-button>
          <div class="postfix-wrapper">
            <div
              v-if="is2FADisabled && walletAddress"
              class="verified-address"
              data-t="verified-address-label-0jxa"
            >
              {{ t('payments.addressBook.confirmed') }}
            </div>
            <StButton
              v-if="!walletAddress"
              :label="t('payments.addressBook.paste')"
              type="text-tertiary"
              @click="pasteAddress"
            />
            <StIcon
              v-else
              name="cross-large"
              size="16"
              type="text-tertiary"
              class="clear-button"
              @click="clearAddress"
            />
          </div>
        </template>
      </StInput>
      <StInput
        v-model="walletName"
        :size="platform === 'desktop' ? 'l' : 'm'"
        :label="t('payments.withdrawal.addressName')"
        :placeholder="t('payments.addressBook.enterWalletName')"
        :error="!!walletNameError"
        :error-message="walletNameError"
        data-t="wallet-name-input-ibg4"
      />
      <div class="address-confirmation">
        <div class="information">
          <div class="information-title">
            {{ t('payments.addressBook.confirmAddress') }}
          </div>
          <div class="information-text">
            {{ t('payments.addressBook.confirmAddressDescription') }}
          </div>
        </div>
        <div class="address-toggle">
          <StToggle
            v-model="is2FADisabled"
            data-t="address-confirmation-toggle-jl4m"
            :size="platform === 'desktop' ? 'm' : 's'"
          />
        </div>
      </div>
      <div class="button-wrapper">
        <StButton
          class="button-save"
          data-t="save-address-button-gjgw"
          :label="t('payments.withdrawal.save')"
          :loading="sendCodeStatus === 'pending'"
          :size="platform === 'desktop' ? 'xl' : 'l'"
          @click="goToSecondStep"
        />
      </div>
    </div>
    <div
      v-if="current === 'second'"
      class="confirmation-code"
      data-t="confirmation-code-3bma"
    >
      <div v-if="platform === 'desktop'" class="go-back-button">
        <StButton
          type="text-only"
          :label="t('authorization.secondStepGoBack')"
          icon="chevron-left"
          is-left-icon
          @click="goTo('first')"
        />
      </div>
      <TwoFactorAuth
        :title="t('payments.addressBook.addAddressConfirmation')"
        :error="addAddressError"
        :platform="platform"
        @code-entered="setEnteredCode"
        @retry="resendCode"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { debounce } from '@st/utils'
import { useCurrenciesStore } from '../../stores/useCurrenciesStore'
import { useAddressesStore } from '../../stores/useAddressesStore'
import TwoFactorAuth from '../TwoFactorAuth/TwoFactorAuth.vue'

const { goTo, current } = useStepper(['first', 'second'])

const currenciesStore = useCurrenciesStore()
const { currencies } = storeToRefs(currenciesStore)
const { goBack } = useRouterBack()
const { open } = useToast()
const { refreshAddresses } = useAddressesStore()

const emit = defineEmits<{
  (e: 'back'): void
  (
    e: 'handleNextStep',
    {
      currencyId,
      networkId,
      addressId,
    }: { currencyId: number; networkId: number; addressId: number | null },
  ): void
  (e: 'currentStepChanged', currentStep: string): void
}>()

const props = withDefaults(
  defineProps<{
    selectedCurrencyId: number
    selectedNetworkId: number
    platform: 'desktop' | 'mobile'
  }>(),
  {
    selectedCurrencyId: 1,
    selectedNetworkId: 0,
    platform: 'desktop',
  },
)

const { t } = useI18n()

const currencyId = ref(props.selectedCurrencyId)
const networkId = ref(props.selectedNetworkId)
const networks = computed(() => currencies.value[currencyId.value]?.networks)

watch(currencyId, () => {
  if (networks.value.length === 1) {
    networkId.value = networks.value[0].id
  } else {
    networkId.value = 0
  }
})

const walletName = ref('')
const walletAddress = ref('')
const is2FADisabled = ref(false)

const {
  execute: sendCode,
  status: sendCodeStatus,
  error: sendCodeError,
  data: addressId,
  refresh: refreshSendCode,
} = useStFetch('/payment-address/create/code/send', {
  method: 'POST',
  immediate: false,
  watch: false,
  body: computed(() => ({
    name: walletName.value,
    address: walletAddress.value,
    currencyId: currencyId.value,
    networkId: networkId.value,
    twoFactorAuthRequired: !is2FADisabled.value,
  })),
})

function resendCode() {
  refreshSendCode()
}

const {
  execute: validateAddress,
  error: validateAddressError,
  refresh: validateRefresh,
} = useStFetch('/payment-address/validate', {
  method: 'POST',
  immediate: false,
  watch: false,
  body: computed(() => ({
    networkId: networkId.value,
    address: walletAddress.value,
  })),
})
const validateAddressDebounce = debounce(validateAddress, 500)

watch(networkId, () => {
  if (!walletAddress.value) return

  validateRefresh()
})

watch(walletAddress, () => {
  validateAddressDebounce()
})

const toast = useToast()

const errorCodes: Record<string, string> = {
  VALIDATION_ERROR: t('payments.addressBook.errorMessages.validationError'),
  USER_UNAUTHORIZED: t('payments.addressBook.errorMessages.userUnathorized'),
  PAYMENT_ADDRESS_ALREADY_EXISTS: t(
    'payments.addressBook.errorMessages.addressAlreadyExists',
  ),
  CURRENCY_NETWORK_PAIR_NOT_FOUND: t(
    'payments.addressBook.errorMessages.pairNotFound',
  ),
  CONFIRMATION_CODE_RESEND_TIMEOUT: t(
    'payments.addressBook.errorMessages.confirmationResetTimeout',
  ),
  PAYMENT_ADDRESS_VALIDATION_FOR_THIS_NETWORK_NOT_SUPPORTED: t(
    'payments.addressBook.errorMessages.paymentAddressValidationNotSupported',
  ),
  PAYMENT_ADDRESS_NOT_VALID: t(
    'payments.addressBook.errorMessages.paymentAddressNotValid',
  ),
  FORBIDDEN_COUNTRY: t('payments.addressBook.errorMessages.forbiddenCountry'),
}

const errorMessage = computed(() => {
  const errorBody = sendCodeError.value?.data
  const validationErrorBody = validateAddressError.value?.data

  if (errorBody) {
    const { error: errorCode } = errorBody

    return errorCodes[errorCode]
  }

  if (validationErrorBody) {
    const { error: errorCode } = validationErrorBody

    return errorCodes[errorCode]
  }

  return undefined
})

function handleNextStep() {
  emit('handleNextStep', {
    currencyId: currencyId.value,
    networkId: networkId.value,
    addressId: addressId.value,
  })
}

const walletNameError = ref('')

watch(walletName, () => {
  walletNameError.value = ''
})

async function goToSecondStep() {
  if (!walletName.value) {
    walletNameError.value = t('payments.addressBook.enterWalletName')
    return
  }

  await sendCode()

  if (sendCodeStatus.value === 'success') {
    goTo('second')
  } else if (
    sendCodeError.value?.data?.error === 'TELEGRAM_SEND_MESSAGE_ERROR'
  ) {
    toast.open({
      label: t('telegram.botRequired'),
      type: 'negative',
    })
  }
}

const confirmationCode = ref('')
function setEnteredCode(value: string) {
  confirmationCode.value = value
}

const {
  execute: addAddress,
  status: addAddressStatus,
  error: addAddressError,
} = useStFetch('/payment-address/create', {
  method: 'POST',
  immediate: false,
  watch: false,
  body: computed(() => ({
    name: walletName.value,
    address: walletAddress.value,
    currencyId: currencyId.value,
    networkId: networkId.value,
    twoFactorAuthRequired: !is2FADisabled.value,
    code: confirmationCode.value,
  })),
})

watch(confirmationCode, (newValue) => {
  if (newValue) {
    addAddress()
  }
})

watch(addAddressStatus, (newValue) => {
  if (newValue === 'success') {
    open({ label: t('payments.addressBook.addressAdded'), type: 'positive' })
    refreshAddresses()
    handleNextStep()
    emit('currentStepChanged', '')
  }
})

watch(current, () => {
  emit('currentStepChanged', current.value)
})

async function pasteAddress() {
  const textFromClipboard = await navigator.clipboard.readText()

  walletAddress.value = textFromClipboard
}

function clearAddress() {
  walletAddress.value = ''
}
</script>

<style scoped>
.add-new-address {
  display: flex;

  .top-section {
    margin-bottom: var(--spacing-100);
  }

  h2 {
    margin: 0;
    font: var(--desktop-text-2xl-semibold);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  min-width: 548px;
}

.address-confirmation {
  display: flex;
  gap: var(--spacing-300);
}

.information {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
}

.information-title {
  font: var(--desktop-text-md-medium);
}

.information-text {
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.address-toggle {
  display: flex;
  width: 66px;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.button-save {
  width: 100%;
}

.confirmation-code {
  height: 562px;
}

.clear-button {
  cursor: pointer;
  color: var(--text-tertiary);
}

.postfix-wrapper {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
}

.verified-address {
  display: flex;
  align-items: center;
  justify-content: center;

  height: fit-content;

  padding: 1px var(--spacing-050) 2px var(--spacing-050); /* stylelint-disable-line */

  font: var(--desktop-text-xxs-medium);
  color: var(--text-success);

  background: rgba(83 198 107 / 12%);
  border-radius: var(--border-radius-full);
}

.add-new-address.mobile {
  flex-grow: 1;

  .content {
    width: 100%;
    min-width: auto;
    padding: var(--spacing-200);
  }

  .address-confirmation {
    position: relative;
  }

  .information {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-050);
  }

  .information-title {
    font: var(--desktop-text-sm-medium);
  }

  .information-text {
    font: var(--mobile-text-content-regular);
  }

  .address-toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: 38px;
  }

  .top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .go-back-text {
    flex-grow: 1;
    font: var(--mobile-title-2-semibold);
  }

  h2 {
    font: var(--mobile-title-2-semibold);
  }

  .checkbox-text {
    font: var(--mobile-text-content-regular);
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .button-save {
    height: auto;
  }

  .confirmation-code {
    width: 100%;
    height: 100%;
  }

  & :deep(.input) {
    padding-right: 90px; /* stylelint-disable-line */
  }
}
</style>
